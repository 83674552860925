import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import rabbitLogo from '../../../../images/logo.svg';
import menu from '../../../../images/menu.svg';
import styles from './NavBar.module.scss';

export default function NavBar({
  isOpen,
  setIsOpen,

}) {
  const navigate = useNavigate();

  function goToHome() {
    navigate('/');
  }
  return (
    <div style={{
      width: '100%',
    }}
    >
      <div className={clsx(styles.navbar)}>
        <div className={clsx(styles.navbar__container)}>
          <div className={clsx(styles.navbar__logo)}>
            <img
              src={rabbitLogo}
              alt="logo"
              onClick={goToHome}
              aria-hidden
            />
          </div>
          <button
            type="button"
            className={clsx(styles.navbar__menu)}
            onClick={() => setIsOpen(!isOpen)}
          >
            <img src={menu} alt="menu" />
          </button>
        </div>
      </div>

    </div>
  );
}

NavBar.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};
