/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-use-before-define */
import clsx from 'clsx';
import React from 'react';
import { NotificationContainer } from 'react-notifications';
import Footer from '../../components/Footer/Footer';
import Menu from '../../components/Menu/Menu';
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';
import rabbitClick from '../../images/rabbit_click.png';
import rabbitOk from '../../images/rabbit_ok.png';
import rabbitSimply from '../../images/rabbit_simply.png';
import LandingContainer from '../Home/components/LandingContainer/LandingContainer';
import NavBar from '../Home/components/NavBar/NavBar';
import SubscriptionContainer from '../Home/components/SubscriptionContainer/SubscriptionContainer';
import AllRabbitsContainer from './components/AllRabbitsContainer/AllRabbitsContainer';
import styles from './styles/Landing.module.scss';

export default function Landing() {
  const [isOpen, setIsOpen] = React.useState(false);

  const scrollToAnchor = (tag) => {
    setIsOpen(false);
    const anchor = document.querySelector(tag);
    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const blueInfo = [
    {
      id: 0,
      title: 'Ogni giorno una sorpresa 🥳🥳🥳',
      text: 'Ogni giorno alle 9 del mattino riceverai un messaggio da un personaggio diverso (noi li chiamiamo Rabbit). Potrai trovarti a conversare con un architetto, un giornalista, un giardiniere',
    },
    {
      id: 1,
      title: 'Come e quando vuoi 🚽💩😳',
      text: 'Quando vuoi cominci a parlarci, senza fretta e con i tuoi tempi e pian piano si svilupperà naturalmente una conversazione tra di voi.',
    },
    {
      id: 2,
      title: 'Un mattoncino alla volta 🧱🔥',
      text: 'Al termine di ogni conversazione ricevi un feedback completo sugli errori che hai commesso e suggerimenti per migliorare lessico specifico, varietà grammaticale e proprietà di linguaggio.',
    },

  ];

  return (
    <div className={clsx(styles.page)}>
      <NotificationContainer />

      <NavBar
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />

      <LandingContainer scrollToAnchor={scrollToAnchor} />

      <img className={clsx(styles.image)} src={rabbitOk} alt="rabbit__languages" />

      <section className={clsx(styles.info__container)}>
        <div className={clsx(styles.info__body)}>
          <h1 className={clsx(styles.info__title)}>
            Basta rimandare!
            Ecco perché questo è il metodo giusto per te ❤️‍🔥🤭
          </h1>
          <p className={clsx(styles.info__text)}>
            Hai presente quella persona che conosci che si è trasferita a Londra
            e dopo qualche mese sembra già un madrelingua?
            <br />
            <br />
            No, non è l’aria della capitale, ma semplicemente
            il fatto che abbia allenato in un il suo inglese in un ambiente reale.

          </p>
        </div>
      </section>

      <section className={clsx(styles.info__container)}>
        <div className={clsx(styles.info__body)}>
          <h1 className={clsx(styles.info__title)}>
            Non devi prenotare un volo e trasferirti in Inghilterra! 🙅✈️ 🇬🇧
          </h1>
          <p className={clsx(styles.info__text)}>
            Se vuoi una soluzione meno drastica, prova Rabbit Talk!
            <br />
            <br />
            Ogni giorno farai conversazione in inglese su tematiche
            diverse con la nostra intelligenza artificiale.
            <br />
            <br />
            Migliorerai le tue capacità di comunicazione,
            espanderai il tuo vocabolario ed eliminerai per sempre
            quei piccoli dubbi o errori che ti imbarazzano.
          </p>
        </div>
      </section>

      <section id="info__come-funziona" className={clsx(styles.blue__container)}>
        <div className={clsx(styles.blue__container__body)}>
          <h1 className={clsx(styles.blue__container__title)}>
            Quindi come funziona nel pratico?
          </h1>
          {
            blueInfo.map((item) => (
              <div key={item.id} className={clsx(styles.blue__container__item)}>
                <h3 className={clsx(styles.blue__container__item__title)}>
                  {item.title}
                </h3>
                <p className={clsx(styles.blue__container__item__text)}>
                  {item.text}
                </p>
              </div>
            ))
          }

        </div>
      </section>
      <div className={clsx(styles.blue__container__image__container)}>
        <img className={clsx(styles.blue__container__image__container__image)} src={rabbitSimply} alt="rabbit__simply" />
      </div>

      <section className={clsx(styles.info__container)}>
        <div className={clsx(styles.info__body)}>
          <h1 className={clsx(styles.info__title)}>
            Bye Bye paura di sbagliare
          </h1>
          <p
            className={clsx(styles.info__text)}
            style={{
              fontStyle: 'italic',
            }}
          >
            “Come si scrive quella parola? Esiste questo modo di dire?
            Forse potrei formulare meglio...”
          </p>
          <p className={clsx(styles.info__text)}>
            Non dovrai più farti di questi problemi! I nostri Rabbit sono basati su un’intelligenza
            artificiale quindi non c’è proprio nulla di cui imbarazzarti.
            Sbaglia, impara e ripeti finchè i nostri rabbit non avranno nulla
            da ridire!
          </p>
        </div>
      </section>

      <SubscriptionContainer />
      <AllRabbitsContainer />

      <section id="info__iscrizione" className={clsx(styles.container__button)}>
        <PrimaryButton
          label="Attiva la prova gratuita"
          onClick={() => scrollToAnchor('#info__costi')}
        />
      </section>

      <img
        className={clsx(styles.image__click)}
        src={rabbitClick}
        alt="rabbit__click"
      />

      <Footer />

      <Menu isOpen={isOpen} onClose={() => setIsOpen(false)} scrollToAnchor={scrollToAnchor} />
    </div>
  );
}
