import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './TextField.module.scss';

export default function TextField({
  label,
  name,
  type,
  placeholder,
  value,
  onChange,
  onBlur,
  error,
  fullWidth,
  prefix,
}) {
  return (
    <div className={clsx(styles.form__group)}>
      {label && <label htmlFor={name}>{label}</label>}
      <div className={clsx(styles.input__box)}>
        {prefix && <span className={clsx(styles.input__prefix)}>{prefix}</span>}
        <input
          id={name}
          className={clsx(styles.input, { [styles.fullWidth]: fullWidth })}
          type={type}
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
        />
      </div>
      {error && <span className={styles.error}>{error}</span>}
    </div>
  );
}

TextField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  error: PropTypes.string,
  fullWidth: PropTypes.bool,
  prefix: PropTypes.string,
};

TextField.defaultProps = {
  label: '',
  type: 'text',
  placeholder: '',
  value: '',
  onChange: () => {},
  onBlur: () => {},
  error: '',
  fullWidth: false,
  prefix: '',
};
