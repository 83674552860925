import clsx from 'clsx';
import React from 'react';
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';
import rabbitOk from '../../images/rabbit_ok.png';
import styles from './CompletePage.module.scss';

export default function CompletePage() {
  const query = new URLSearchParams(window.location.search);
  const code = query.get('code');

  function openWhatsapp(codice) {
    window.open(`https://api.whatsapp.com/send/?phone=393517941833&text=${codice}&type=phone_number&app_absent=0`, '_blank');
  }

  return (
    <div className={clsx(styles.page)}>
      <div className={clsx(styles.page__container)}>
        <img className={clsx(styles.page__container__image)} src={rabbitOk} alt="rabbit__ok" />
        <div className={clsx(styles.page__container__title)}>
          <h1 className={clsx(styles.page__container__title__text)}>
            Tutto fatto qui, apri l&aposapp e segui le
            indicazioni.
          </h1>
          <p>
            Non dimeticare di inserire il codice:
            <b>{code}</b>
          </p>
        </div>
        <div className={clsx(styles.page__container__body)}>
          <PrimaryButton label="Apri Whatsapp" onClick={() => openWhatsapp(code)} />
        </div>
      </div>
    </div>
  );
}
