import { ThemeProvider } from '@emotion/react';
import { CssBaseline, createTheme } from '@mui/material';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import CheckoutPage from './pages/CheckoutPage/CheckoutPage';
import CompletePage from './pages/CompletePage/CompletePage';
import Landing from './pages/Landing/Landing';
import PaymentPage from './pages/PaymentPage/PaymentPage';
import PrivacyPage from './pages/PrivacyPage/PrivacyPage';
import TermsPage from './pages/TermsPage';

const theme = createTheme({
  palette: {
    primary: {
      main: '#FF5C00',
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          background: '#fff',
          border: 'none',
          outline: 'none',
          padding: '0.5rem',
          color: '#959595',
          fontSize: '1rem',
          fontFamily: 'Poppins',
          lineHeight: '1.44rem',
          '&::placeholder': {
            color: '#959595',
          },
        },
      },
    },
  },
});
function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline enableColorScheme />
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/payment" element={<PaymentPage />} />
          <Route path="/complete" element={<CompletePage />} />
          <Route path="/checkout" element={<CheckoutPage />} />
          <Route path="/terms" element={<TermsPage />} />
          <Route path="/privacy" element={<PrivacyPage />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
