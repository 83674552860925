import clsx from 'clsx';
import React, { useEffect } from 'react';

import api from '../../api';
import styles from './styles/CheckoutPage.module.scss';

export default function CheckoutPage() {
  const { search } = window.location;
  const params = new URLSearchParams(search);
  const token = params?.get('t');

  async function getLinkPaymentApi(req) {
    const response = await api.get(`/v2/user/paymentUrl?tel=${req.token}`);
    console.log('response  ', response);
    return response.data;
  }

  const getLinkPayment = () => {
    if (token !== '') {
      // user API call
      getLinkPaymentApi({
        token,
      })
        .then((res) => {
          window.open(res.paymentUrl, '_self');

          // handleClose();
          // setIsSubmit(false);
          // const data = {
          //   type: "success",
          //   open: true,
          //   message: res.msg,
          // };
          // localStorage.setItem("snackbar", JSON.stringify(data));
        })
        .catch((error) => {
          console.log(error);
          console.log(error.response.data);
        });
    }
  };

  useEffect(() => {
    if (token) {
      getLinkPayment();
    }
  }, [token]);

  return (
    <div className={clsx(styles.page)}>
      {/* <NavBar isOpen={openMenu} setIsOpen={setOpenMenu} />
      <div className={clsx(styles.page__container)}>
        <div className={clsx(styles.page__container__title)}>
          <h1 className={clsx(styles.page__container__title__text)}>Domande frequenti</h1>
        </div>
        <div className={clsx(styles.page__container__faq)}>
          {faq.map((item) => (
            <Accordion>
              <AccordionSummary
                aria-controls="panel1a-content"
                id="panel1a-header"
                expandIcon={<ExpandMoreRounded />}
              >
                <h3 className={clsx(styles.page__container__faq__question)}>{item.question}</h3>
              </AccordionSummary>
              <AccordionDetails>{item.answer}</AccordionDetails>
            </Accordion>
          ))}
        </div>
      </div>
      <Footer />
      <Menu
        isOpen={openMenu}
        setIsOpen={setOpenMenu}
        onClose={() => setOpenMenu(false)}
        scrollToAnchor={scrollToAnchor}
      /> */}
    </div>
  );
}
