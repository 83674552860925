/* eslint-disable react/no-unescaped-entities */
import clsx from 'clsx';
import React from 'react';
import styles from './Footer.module.scss';

export default function Footer() {
  const info = [
    {
      id: 1,
      title: 'Company Information',
      description: 'Zumbat Hub SRL\nP.Iva: 10928040962',
    },
    {
      id: 2,
      title: 'Contact',
      description: 'info@zumbat.it',
    },
    {
      id: 3,
      title: 'Addresses',
      description: 'Business Office: Viale Abruzzi 52, Milano -20131',
    },

  ];
  return (
    <div className={clsx(styles.footer)}>
      <div className={clsx(styles.footer__container)}>
        {
          info.map((item) => (
            <div
              key={item.id}
              className={clsx(styles.footer__container__item)}
              onClick={item.event}
              role="button"
              aria-hidden
              style={
                item.event ? { cursor: 'pointer' } : { cursor: 'default' }
              }
            >
              <h1 className={clsx(styles.footer__title)}>
                {item.title}
              </h1>
              {
                item.description && (
                  <p className={clsx(styles.footer__description)}>
                    {item.description}
                  </p>
                )
              }

            </div>
          ))
        }
      </div>
    </div>
  );
}
