/* eslint-disable import/no-extraneous-dependencies */
import clsx from 'clsx';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../../api';
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';
import TextField from '../../components/TextField/TextField';
import rabbitLove from '../../images/rabbit_love.png';
import rabbitTop from '../../images/rabbit_top.svg';
import SnackbarComponent from '../../components/Snackbar/Snackbar';
import styles from './styles/PaymentPage.module.scss';

export default function PaymentPage() {
  const navigate = useNavigate();
  const [openSnackbar, setOpenSnackbar] = React.useState({
    open: false,
    message: '',
    type: '',
  });

  const query = new URLSearchParams(window.location.search);
  const success = query.get('success');
  const token = query?.get('t');

  const [email, setEmail] = React.useState('');

  function handleSendEmail() {
    try {
      const response = api
        .post(
          '/user/edit',
          { email },
          {
            headers: {
              Authorization: `${token}`,
            },
          },
        )
        .then((res) => {
          if (res.status === 200) {
            setOpenSnackbar({
              open: true,
              message: 'Congratulazioni! Hai completato la tua registrazione.',
              type: 'success',
            });
            // aspetta due secondi e poi vai alla home
            setTimeout(() => {
              navigate('/');
            }, 2000);
          }
        });
      console.log(response);
    } catch (error) {
      setOpenSnackbar({
        open: true,
        message: 'Si è verificato un errore durante la registrazione.',
        type: 'error',
      });
    }
  }

  function handleChange(e) {
    const { value } = e.target;
    setEmail(value);
  }

  function goToHome() {
    navigate('/');
  }

  return (
    <div className={clsx(styles.page)}>
      <SnackbarComponent
        data={openSnackbar}
        closeSnackbar={() => {
          setOpenSnackbar({
            open: false,
            message: '',
            type: '',
          });
        }}
      />
      <div className={clsx(styles.page__container)}>
        {success === 'true' ? (
          <div className={clsx(styles.page__container__success)}>
            <h1 className={clsx(styles.page__container__success__title)}>
              Pagamento effettuato con successo!
            </h1>
            <img
              className={clsx(styles.page__container__success__image)}
              src={rabbitLove}
              alt="Rabbit Love"
            />
            <TextField
              label="Email"
              value={email}
              onChange={(e) => handleChange(e)}
              placeholder="Inserisci la tua email"
              type="email"
              name="email"
              fullWidth
            />
            <PrimaryButton onClick={() => handleSendEmail()} label="Salva" />
          </div>
        ) : (
          <div className={clsx(styles.page__container__success)}>
            <h1 className={clsx(styles.page__container__success__title)}>Ops!</h1>
            <p className={clsx(styles.page__container__success__subtitle)}>
              Purtroppo si è verificato un errore con il pagamento.
            </p>
            <img
              className={clsx(styles.page__container__success__image)}
              src={rabbitTop}
              alt="Rabbit Top"
            />

            <PrimaryButton label="Torna alla home" onClick={() => goToHome()} />
          </div>
        )}
      </div>
    </div>
  );
}
