/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import PrimaryButton from '../../../../components/PrimaryButton/PrimaryButton';
import styles from './LandingContainer.module.scss';

export default function LandingContainer({ scrollToAnchor }) {
  return (
    <div className={clsx(styles.landing__container)}>
      <div className={clsx(styles.landing__container__title__container)}>
        <h1 className={clsx(styles.landing__container__title__container__title)}>Allenare l'inglese è facile e veloce come mandare un messaggio</h1>
      </div>
      <div className={clsx(styles.landing__container__description__container)}>
        <h2 className={clsx(styles.landing__container__description)}>Basta rimandare. Sviluppa le tue capacità di interagire nel mondo reale e migliora il tuo inglese grazie a chat sempre divertenti e realistiche. </h2>
      </div>
      <div className={clsx(styles.landing__container__title__button__container)}>
        <PrimaryButton label="Scopri la prova gratuita" fullWidth onClick={() => { scrollToAnchor('#info__costi'); }} />
      </div>
    </div>
  );
}

LandingContainer.propTypes = {
  scrollToAnchor: PropTypes.func.isRequired,
};
