import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ImageModal from '../../../../components/ImageModal/ImageModal';
import danielAvatar from '../../../../images/daniel_avatar.svg';
import elisabethAvatar from '../../../../images/elisabeth_avatar.svg';
import ethanAvatar from '../../../../images/ethan_avatar.svg';
import evelynAvatar from '../../../../images/evelyn_avatar.svg';
import jamesAvatar from '../../../../images/james_avatar.svg';
import danielPreview from '../../../../images/preview/daniel_preview.png';
import elisabethPreview from '../../../../images/preview/elisabeth_preview.png';
import ethanPreview from '../../../../images/preview/ethan_preview.png';
import evelynPreview from '../../../../images/preview/evelyn_preview.png';
import jamesPreview from '../../../../images/preview/james_preview.png';
import samanthaPreview from '../../../../images/preview/samantha_preview.png';
import samanthaAvatar from '../../../../images/samantha_avatar.svg';
import styles from './AllRabbitsContainer.module.scss';

function RabbitCard({ rabbit }) {
  return (
    <div className={clsx(styles.rabbit__card)}>
      <div className={clsx(styles.rabbit__card__header)}>
        <div className={clsx(styles.rabbit__card__info)}>
          <img
            className={clsx(styles.rabbit__card__image)}
            src={rabbit.avatar}
            alt={rabbit.name}
          />
          <div>
            <h3 className={clsx(styles.rabbit__card__info__name)}>{rabbit.name}</h3>
            <p className={clsx(styles.rabbit__card__info__work)}>{rabbit.work}</p>
          </div>
        </div>
        {/* <button
          type="button"
          className={clsx(styles.rabbit__card__button)}
          onClick={() => handleOpenScreenShot(rabbit.image)}
        >
          {'>'}

        </button> */}
      </div>
      <div className={clsx(styles.rabbit__card__description)}>
        <p className={clsx(styles.rabbit__card__description__text)}>{rabbit.description}</p>
      </div>
    </div>
  );
}

RabbitCard.propTypes = {
  rabbit: PropTypes.shape({
    id: PropTypes.number.isRequired,
    avatar: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    work: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
  }).isRequired,
  // handleOpenScreenShot: PropTypes.func.isRequired,
};

export default function AllRabbitsContainer() {
  const rabbits = [
    {
      id: 1,
      avatar: samanthaAvatar,
      image: samanthaPreview,
      name: 'Samantha 📷',
      work: 'Fotografa',
      description: 'Samantha è una fotografa molto brava, con lei potrai parlare di shooting fotografici di ogni genere tra cui ritratti, matrimoni, moda e tanto altro. Potrai chiederle di condividere le sue tecniche preferite sia per gli scatti che per la post-produzione, oppure potrai farti consigliare fotocamere, obiettivi e altri accessori.',
    }, {
      id: 2,
      avatar: jamesAvatar,
      image: jamesPreview,
      name: 'James 🏀',
      work: 'Giornalista Sportivo',
      description: 'James è un giornalista molto preparato, la sua passione è il basket. Potrai parlare con lui di eventi sportivi, risultati, statistiche, delle squadre e degli atleti.',
    },
    {
      id: 3,
      avatar: danielAvatar,
      image: danielPreview,
      name: 'Daniel 👔',
      work: 'Stilista',
      description: 'Daniel è uno stilista, vive a Parigi e ama la moda. Gli piace molto consigliare a chiunque incontri l’outfit perfetto per ogni tipo di occasione. Se hai in programma un evento importate chiedi a lui cosa indossare, sarà felice di aiutarti.',
    },
    {
      id: 4,
      avatar: elisabethAvatar,
      image: elisabethPreview,
      name: 'Elisabeth 🧘‍♀️',
      work: 'Compagna Yoga',
      description: 'Elizabeth segue con te il corso di yoga. Potrai parlare con lei dell’utilità dello yoga. La pratica regolare dello yoga può offrire tanti benefici, parlane con lei!',
    },
    {
      id: 5,
      avatar: evelynAvatar,
      image: evelynPreview,
      name: 'Evelyn 🎨',
      work: 'Graphic Designer',
      description: 'Evelyn è una Graphic designer, le piace molto parlare del suo lavoro e delle sue esperienze. Potrete parlare di molte cose: i suoi progetti preferiti, le tendenze attuali che deve seguire, gli strumenti che utilizza.',
    },
    {
      id: 6,
      avatar: ethanAvatar,
      image: ethanPreview,
      name: 'Ethan 🎸',
      work: 'Musicista',
      description: 'Ethan è un musicista, gli piace parlare del suo mondo: la musica! Potrete parlare di strumenti, generi musicali, cantanti e band.',
    },

  ];

  const [isScreenshotOpen, setIsScreenshotOpen] = useState(false);
  const [selectedRabbit, setSelectedRabbit] = useState('');

  const handleOpenScreenShot = (rabbit) => {
    setSelectedRabbit(rabbit);
    setIsScreenshotOpen(true);
  };

  const handleCloseScreenShot = () => {
    setIsScreenshotOpen(false);
  };

  return (
    <section className={clsx(styles.container)}>
      <div className={clsx(styles.title__container)}>
        <h2 className={clsx(styles.title)}>Scopri i tuoi rabbits</h2>
        <p className={clsx(styles.subtitle)}>
          La nostra intelligenza artificiale interpreterà per
          te delle persone vere e proprie e ti aiuterà ad
          allenarti in contesti reali. Per rendere il
          tutto più immersivo ogni rabbit ha le sue passioni
          e i suoi hobby, leggine alcuni qui sotto!
        </p>
      </div>
      <div className={clsx(styles.rabbits__container)}>
        {rabbits.map((rabbit) => (
          <RabbitCard
            key={rabbit.id}
            rabbit={rabbit}
            handleOpenScreenShot={handleOpenScreenShot}
          />
        ))}
      </div>
      <ImageModal
        isOpen={isScreenshotOpen}
        image={selectedRabbit}
        onClose={handleCloseScreenShot}
      />
    </section>
  );
}
