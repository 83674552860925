/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import clsx from 'clsx';
import React from 'react';
import styles from './PrivacyPage.module.scss';

export default function TermsPage() {
  return (
    <div className={clsx(styles.page)}>
      <div className={clsx(styles.page__container)}>
        <h1 className={clsx(styles.page__container__title)}>Privacy policy</h1>
        <p className={clsx(styles.page__container__text)}>
          <p>Privacy Policy Presso Rabbit Talk, accessibile su rabbit-talk.com, una delle nostre principali priorit&agrave; &egrave; la privacy dei nostri visitatori. Questo documento sulla Privacy Policy contiene tipi di informazioni che vengono raccolte e registrate da Rabbit Talk e come le utilizziamo.</p>

          <p>Se hai domande aggiuntive o hai bisogno di ulteriori informazioni sulla nostra Privacy Policy, non esitare a contattarci.</p>

          <p>Questa Privacy Policy si applica solo alle nostre attivit&agrave; online ed &egrave; valida per i visitatori della nostra App per quanto riguarda le informazioni che condividono e/o raccolgono su Rabbit Talk. Questa politica non &egrave; applicabile a informazioni raccolte offline o tramite canali diversi da questa App. La nostra Privacy Policy &egrave; stata creata con l'aiuto del Generatore di Privacy Policy Gratuito.</p>

          <p>Consenso: Utilizzando la nostra App, acconsenti alla nostra Privacy Policy e accetti i suoi termini.</p>

          <p>Informazioni che raccogliamo: Le informazioni personali che ti vengono richieste di fornire e le ragioni per cui ti viene chiesto di fornirle ti verranno chiaramente indicate nel momento in cui ti chiederemo di fornire le tue informazioni personali.</p>

          <p>Se ci contatti direttamente, potremmo ricevere ulteriori informazioni su di te, come il tuo nome, indirizzo email, numero di telefono, il contenuto del messaggio e/o allegati che potresti inviarci e qualsiasi altra informazione che scegli di fornire.</p>

          <p>Quando ti registri per un Account, potremmo chiederti le tue informazioni di contatto, tra cui elementi come il nome, il nome dell'azienda, l'indirizzo, l'indirizzo email e il numero di telefono.</p>

          <p>Come utilizziamo le tue informazioni: Utilizziamo le informazioni che raccogliamo in vari modi, tra cui:</p>

          <p>- Fornire, gestire e mantenere il nostro sito web - Migliorare, personalizzare ed espandere il nostro sito web - Comprendere e analizzare come utilizzi il nostro sito web - Sviluppare nuovi prodotti, servizi, funzionalit&agrave; e funzionalit&agrave; - Comunicare con te, direttamente o attraverso uno dei nostri partner, inclusi il servizio clienti, per fornirti aggiornamenti e altre informazioni relative al sito web e per scopi di marketing e promozionali - Inviarti email - Trovare e prevenire frodi File di Registro: Rabbit Talk segue una procedura standard di utilizzo dei file di registro. Questi file registrano i visitatori quando visitano i siti web. Tutte le aziende di hosting lo fanno e fa parte delle analisi dei servizi di hosting. Le informazioni raccolte dai file di registro includono indirizzi protocollo Internet (IP), tipo di browser, Internet Service Provider (ISP), data e ora, pagine di riferimento/uscita e possibilmente il numero di clic. Questi dati non sono collegati a informazioni personali identificabili. Lo scopo delle informazioni &egrave; analizzare le tendenze, amministrare il sito, tracciare il movimento degli utenti sul sito web e raccogliere informazioni demografiche.</p>

          <p>Cookie e Web Beacon: Come qualsiasi altra App, Rabbit Talk utilizza 'cookie'. Questi cookie vengono utilizzati per memorizzare informazioni, tra cui le preferenze dei visitatori e le pagine del sito web a cui il visitatore ha avuto accesso o visitato. Le informazioni vengono utilizzate per ottimizzare l'esperienza degli utenti personalizzando il contenuto delle nostre pagine web in base al tipo di browser e/o ad altre informazioni dei visitatori.</p>

          <p>Privacy Policy dei Partner Pubblicitari: Puoi consultare questa lista per trovare la Privacy Policy di ciascuno dei partner pubblicitari di Rabbit Talk.</p>

          <p>Server pubblicitari o reti pubblicitarie di terze parti utilizzano tecnologie come cookie, JavaScript o Web Beacon che vengono utilizzate nei rispettivi annunci e collegamenti visualizzati su Rabbit Talk e che vengono inviati direttamente al browser degli utenti. Queste tecnologie vengono utilizzate per misurare l'efficacia delle loro campagne pubblicitarie e/o per personalizzare i contenuti pubblicitari che visualizzi sui siti web che visiti.</p>

          <p>Tieni presente che Rabbit Talk non ha accesso o controllo su questi cookie utilizzati da inserzionisti di terze parti.</p>

          <p>Privacy Policy di Terze Parti: La Privacy Policy di Rabbit Talk non si applica ad altri inserzionisti o siti web. Pertanto, ti consigliamo di consultare le rispettive Privacy Policy dei server pubblicitari di terze parti per informazioni pi&ugrave; dettagliate. Potrebbe includere le loro pratiche e istruzioni su come esercitare l'opzione di opt-out su determinate opzioni.</p>

          <p>Puoi scegliere di disabilitare i cookie tramite le opzioni del tuo browser individuale. Per ottenere informazioni pi&ugrave; dettagliate sulla gestione dei cookie con browser web specifici, puoi consultarle sui rispettivi siti web dei browser.</p>

          <p>Diritti sulla Privacy CCPA (Non Vendere le Mie Informazioni Personali) In base al CCPA, tra gli altri diritti, i consumatori californiani hanno il diritto di:</p>

          <p>- Richiedere che un'azienda che raccoglie i dati personali di un consumatore divulghi le categorie e le specifiche informazioni personali che l'azienda ha raccolto sui consumatori. - Richiedere che un'azienda elimini tutti i dati personali del consumatore che l'azienda ha raccolto. - Richiedere che un'azienda che vende i dati personali di un consumatore non vendi i dati personali del consumatore.</p>

          <p>Se fai una richiesta, abbiamo un mese di tempo per risponderti. Se desideri esercitare uno qualsiasi di questi diritti, ti preghiamo di contattarci.</p>

          <p>Diritti sulla Protezione dei Dati GDPR: Vogliamo assicurarci che tu sia pienamente consapevole di tutti i tuoi diritti in materia di protezione dei dati. Ogni utente ha diritto a quanto segue:</p>

          <p>- Il diritto di accesso - Hai il diritto di richiedere copie dei tuoi dati personali. Potremmo addebitarti una piccola commissione per questo servizio. - Il diritto di rettifica - Hai il diritto di richiedere la correzione di qualsiasi informazione che ritieni essere inaccurata. Hai anche il diritto di richiedere che completiamo le informazioni che ritieni essere incomplete. - Il</p>

          <p> diritto alla cancellazione - Hai il diritto di richiedere la cancellazione dei tuoi dati personali, in determinate circostanze. - Il diritto di limitare il trattamento - Hai il diritto di richiedere che limitiamo il trattamento dei tuoi dati personali, in determinate circostanze. - Il diritto di opporsi al trattamento - Hai il diritto di opporsi al nostro trattamento dei tuoi dati personali, in determinate circostanze. - Il diritto alla portabilit&agrave; dei dati - Hai il diritto di richiedere che trasferiamo i dati che abbiamo raccolto a un'altra organizzazione o direttamente a te, in determinate circostanze.</p>

          <p>Se fai una richiesta, abbiamo un mese di tempo per risponderti. Se desideri esercitare uno qualsiasi di questi diritti, ti preghiamo di contattarci.</p>

          <p>Informazioni per i Minori: Un'altra parte delle nostre priorit&agrave; &egrave; aggiungere una protezione per i minori durante l'uso di Internet. Incoraggiamo i genitori e i tutori a osservare, partecipare e/o monitorare e guidare l'attivit&agrave; online dei loro figli.</p>

          <p>Rabbit Talk non raccoglie consapevolmente alcuna Informazione Personale Identificabile da parte di bambini di et&agrave; inferiore a 9 anni. Se pensi che tuo figlio abbia fornito questo tipo di informazioni sul nostro sito web, ti incoraggiamo vivamente a contattarci immediatamente e faremo del nostro meglio per rimuovere prontamente tali informazioni dai nostri archivi.</p>
        </p>
      </div>
    </div>
  );
}
