import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './ImageModal.module.scss';

export default function ImageModal({
  isOpen,
  image,
  onClose,
}) {
  return (
    <div className={clsx(styles.modal, isOpen && styles.open)}>
      <div className={clsx(styles.modal__content)}>
        <div className={clsx(styles.modal__header)}>
          <button
            className={clsx(styles.modal__close)}
            type="button"
            onClick={onClose}
          >
            x
          </button>
        </div>
        <div className={clsx(styles.modal__body)}>
          <img className={clsx(styles.modal__body__image)} src={image} alt="screenshot" />
        </div>
      </div>
    </div>
  );
}

ImageModal.propTypes = {
  image: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
