import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './PrimaryButton.module.scss';

export default function PrimaryButton(
  {
    label, onClick, fullWidth, type,
    disabled,
  },
) {
  return (
    <button
      type={type === 'submit' ? 'submit' : 'button'}
      className={clsx(
        styles.primary__button,
        fullWidth && styles.primary__button__fullWidth,
        disabled && styles.primary__button__disabled,
      )}
      onClick={onClick}
      disabled={disabled}
    >
      <span className={styles.label}>{label}</span>
    </button>

  );
}

PrimaryButton.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  fullWidth: PropTypes.bool,
  type: PropTypes.string,
  disabled: PropTypes.bool,
};

PrimaryButton.defaultProps = {
  fullWidth: false,
  onClick: () => {},
  type: 'button',
  disabled: false,
};
