/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
import CloseIcon from '@mui/icons-material/Close';
import { Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import * as React from 'react';

const Alert = React.forwardRef((props, ref) => (
  <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
));
function SnackbarComponent(props) {
  const [state] = React.useState({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });
  const { vertical, horizontal } = state;
  // Close Snackbar
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      props.closeSnackbar(true);
      return;
    }
    props.closeSnackbar(true);
  };
  // Snackbar design
  const action = (
    <>
      {/* <Button color="secondary" className="undo_button" onClick={handleCloseSnackbar}>
        UNDO
      </Button> */}
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnackbar}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );
  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={props.data.open}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        action={action}
        key={vertical + horizontal}
      >
        <Alert onClose={handleCloseSnackbar} severity={props.data.type} sx={{ width: '100%' }}>
          {props.data.message}
        </Alert>
      </Snackbar>
    </div>
  );
}
export default SnackbarComponent;
