/* eslint-disable react/jsx-no-bind */
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './styles/Menu.module.scss';

export default function Menu({ isOpen, onClose, scrollToAnchor }) {
  return (
    <div
      className={clsx(styles.menu, { [styles.open]: isOpen })}
      onClick={onClose}
      aria-hidden
    >
      <div
        className={clsx(styles.menu__content)}
        aria-hidden
        onClick={(e) => e.stopPropagation()}
      >
        <div className={clsx(styles.menu__header)}>
          <button
            type="button"
            className={clsx(styles.menu__header__close)}
            onClick={onClose}
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className={clsx(styles.menu__body)}>
          <button
            type="button"
            className={clsx(
              styles.menu__body__item,
              styles.menu__body__item__link,
            )}
            onClick={() => scrollToAnchor('#info__costi')}
          >
            Iscriviti
          </button>

          <button
            type="button"
            className={clsx(
              styles.menu__body__item,
              styles.menu__body__item__link,
            )}
            onClick={() => scrollToAnchor('#info__come-funziona')}
          >
            Come funziona
          </button>
          <button
            type="button"
            className={clsx(
              styles.menu__body__item,
              styles.menu__body__item__link,
            )}
            onClick={() => scrollToAnchor('#info__costi')}
          >
            Quanto costa
          </button>

        </div>
      </div>
    </div>
  );
}

Menu.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  scrollToAnchor: PropTypes.func.isRequired,
};
