import clsx from 'clsx';
import React from 'react';
import PrimaryButton from '../../../../components/PrimaryButton/PrimaryButton';
import styles from './SubscriptionContainer.module.scss';

export default function SubscriptionContainer() {
  const subscriptions = [
    {
      title: 'Nessun costo nascosto',
      type: null,
      color: '#BCDA8A',
      features: [
        {
          id: -1,
          text: '🗓️ Abbonamento mensile 5,90€',
        },
        {
          id: 0,
          text: '🤑 Piani annuali super scontati',
        },
        {
          id: 1,
          text: '✅ Prova gratuita per 3 giorni',
        },
        {
          id: 2,
          text: '🚫 Senza vincoli, disdici quando vuoi',
        },
        {
          id: 3,
          text: '🏃 Direttamente da app',
        },
        {
          id: 4,
          text: '❤️ Solo se ti piace rinnovi',
        },
      ],
      buttonText: 'Scarica sullo store',
    },
  ];

  const userAgent = navigator.userAgent.toLowerCase();

  const isiPad = /ipad/.test(userAgent);
  const isiOS = /iphone|ipod/.test(userAgent);
  const isMacOS = /macintosh|mac os x/.test(userAgent);
  const onApple = isiPad || isiOS || isMacOS;
  function openAppStore() {
    window.open('https://apps.apple.com/it/app/rabbit-talk/id6463021352', '_blank');
  }

  function openPlayStore() {
    window.open('https://play.google.com/store/apps/details?id=com.zumbat.rabbit', '_blank');
  }

  function openStore() {
    if (onApple) {
      openAppStore();
    } else {
      openPlayStore();
    }
  }

  return (
    <section id="info__costi" className={clsx(styles.container)}>
      <div className={clsx(styles.title__container)}>
        <h2 className={clsx(styles.title)}>Pronto a saltare su?</h2>
      </div>
      <div className={clsx(styles.rabbits__container)}>

        {subscriptions.map((subscription) => (
          <div
            style={{ backgroundColor: subscription.color }}
            key={subscription.title}
            className={clsx(styles.subscription__card)}
          >
            <h4 className={clsx(styles.subscription__card__title)}>
              {subscription.title}
            </h4>
            <p className={clsx(styles.subscription__card__type)}>
              {subscription.type}
            </p>
            <ul className={clsx(styles.subscription__card__features)}>
              {subscription.features.map((feature) => (
                <li
                  key={feature.id}
                  className={clsx(styles.subscription__card__features__feature)}
                >
                  {feature.text}
                </li>
              ))}
            </ul>
            <PrimaryButton
              label={subscription.buttonText}
              onClick={() => openStore()}
            />
          </div>
        ))}
      </div>

    </section>
  );
}
